.contact-tags {
  .msg-tag {
    color: $gray-a;
    border: 1px solid $gray-a;
  }

  .client {
    color: $main-yellow;
    border: 1px solid $main-yellow;
  }

  .tutor {
    color: $main-blue;
    border: 1px solid $main-blue;
  }

  .insta {
    color: $main-pink;
    border: 1px solid $main-pink;
  }

  .referral {
    color: #dc4141;
    border: 1px solid #dc4141;
  }
}
