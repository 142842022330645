// font
$font-family: 'DM Sans', sans-serif;
$primary-text-color: #1c1c1e;
$secondary-text-color: #ffffff;
// header
$header-font-xl: 2.6rem;
$header-font-l: 1.8rem;
$header-font-m: 1.8rem;
$header-font-s: 1.6rem;
// body
$body-font-l: 1.6rem;
$body-font-m: 1.4rem;
$body-font-s: 1.3rem;

// specific fonts
$logo-font: 1.2rem;
$dashboard-font: 5rem;
$add-div-font: 1.2rem;
$label-font-size: 1.4rem;
$label-font-s: 1.2rem;
$delete-font-size: 1.4rem;
$avatar-font: 1.2rem;
$client-avatar-font: 1.8rem;
$student-avatar-font: 1.6rem;

// color scheme - dark --> light colours A --> Z
$nav-color: #162947;
$main-blue: #4d7bd7;
$main-pink: #dc659c;
$main-orange: #f18659;
$main-yellow: #e9b255;

$main-gradient: linear-gradient(to right, $main-pink, $main-orange, $main-orange);

$dash-blue-a: #162947;
$dash-blue-b: #294290;

$tag-purple-a: #af25d5;
$tag-purple-b: #c74be9;
$tag-purple-c: #e893ff;
$tag-blue-a: #2a28d5;
$tag-blue-b: #5655f9;
$tag-blue-c: #807ffb;
$tag-teal-a: #15a9c3;
$tag-teal-b: #53cce0;
$tag-teal-c: #99d8e3;
$tag-green-a: #129d7a;
$tag-green-b: #20ca9f;
$tag-green-c: #76dac1;

// grays
$gray-a: #1c1c1e;
$gray-b: #868b95;
$gray-c: #aaafb7;
$gray-d: #d7dae0;
$gray-e: #e6e8ec;
$gray-f: #f6f7f9;

$main-shadow: #d7dae0;

$success: #4f8a10;
$delete: #ec2626;
$imessage: #007aff;
$internal: #f4e3c5;
$outbound: #e6ebf5;

// labels
$label-margin: 1rem;
$label-radius: 3px;

// nav
$nav-width: 18.5vw;
$nav-link-hover: #2d3e59;
$nav-link-height: 5.54vh;
$nav-link-width: 15.14vw;
$nav-font-size: 1.6rem;
$nav-font-color: #f3f3f3;
$nav-link-radius: 6px;
$header-height: 6.2rem;
$nav-padding: 0.8rem;
$nav-link-padding: 1.3rem 1.1rem;
$top-nav-shadow: 0 0.2rem 0.4rem $main-shadow;

// buttons
$default-button-width: 17vw;
$default-button-height: 4.92vh;
$button-font-size: 1.4rem;
$button-font-weight: 500;
$button-padding-top: 0.6rem;
$button-side-padding: 2rem;
$button-radius: 17.5px;

$create-lead-padding: 1.69vh 1.5vw;

// cards
$card-width: 21.89vw;

// form
$form-width: 70vw;
$form-padding: 2.8rem;
$form-radius: 8px;
$form-radius-b: 12px;
$notes-max-height: 9.6vh;

$tutor-container-height: 43vh;

// inputs
$default-input-width: 29.5vw;
$default-input-height: 5.34vh;
// $default-input-height: 6.8vh;
$input-font-size: 1.6rem;
$input-radius: 6px;
$input-padding-left: 1.3rem;
$label-margin-top: 0.6rem;
$checkbox-width: 2rem;
$student-input-width: 27vw;
$subject-input-width: 17.5vw;

$textarea-height: 17.44vh;
$textarea-smaller: 14vh;

// margin padding
$container-mwidth: 4vw;
$default-mheight: 3.46vh;
$default-mwidth: 1.61vw;
$count-margin: 2vw;

$default-grid-gap: 4.44vw;
// form headings
$form-heading-m: 1.7rem;

// transitions
$transition: all 0.2s ease;

//chat
$message-container-height: 62vh;
