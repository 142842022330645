.storiesUpload {
  &__input {
    width: 200px;
    margin-right: 20px;
  }
  &__input-group {
    display: flex;
    flex-direction: row;
  }
}
