.resourceUpload {
   padding: 5rem;

   &__input-group {
      margin-bottom: 2rem;
      width: 100%;
      display: flex;
      justify-content: space-between;
   }

   &__input {
      padding: 1rem;
      // width: 65%;
   }

   &__select-wrapper {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      margin-bottom: 2rem;
   }

   &__text-area {
      width: 75%;
   }

   &__subcategories-wrapper,
   &__tags-wrapper {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 2rem;
   }

   &__subcategory-input,
   &__tags-input {
      margin-right: 1rem;
   }

   &__btn-wrapper {
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 5rem 0;
   }

   &__form-button {
      margin: 0 2rem;
   }
}