.messages-container {
  position: relative;
  background-color: #ffffff;
  // padding-top: 7.75rem;
  z-index: 30;
  .header-button {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .header-title {
      padding-top: $default-mheight;
      margin-left: 2.2rem;
      // margin-left: 5.99vw;
    }
    .gradient {
      background: linear-gradient(to right, $main-pink, $main-orange);
      margin-right: $container-mwidth;
    }
    button.gradient {
      width: 142px;
      max-width: 142px;
    }
    .gradient:hover,
    .gradient:focus {
      background: #ffffff;
      color: $main-pink;
      border: 1px solid $main-pink;
    }
  }
}

.modal {
  position: fixed;
  // top: 50%;
  left: 40%;
  z-index: 10;
  background-color: #ffffff;
  width: 490px;
  // margin-left: 240px;
  padding: $form-padding;
  border-radius: $form-radius;
  box-shadow: 0 7px 22px 0 rgba(0, 0, 0, 0.31);
  .heading {
    span {
      cursor: pointer;
    }
  }
  .number-select {
    display: flex;
    align-items: center;
    min-width: 100%;
    width: 100%;
    margin-top: 0.5rem;
    .select-container {
      .select-icon {
        top: 20%;
      }
      select {
        min-width: 100%;
      }
    }
    .default-margin-top-bottom.isa_success,
    .default-margin-top-bottom.isa_error {
      margin: 0;
      margin-left: 1.2rem;
      width: calc(100% - 29.5vw);
    }
  }
  .search-bar {
    width: 100%;
    margin: 1.5rem 0;
    padding: 0;
  }
  textarea[name='outboundSMS'] {
    width: 100%;
  }
}

.reverse-btn {
  background: #ffffff;
  color: $main-blue;
  border: 1px solid $main-blue;
}
.reverse-btn:hover,
.reverse-btn:focus {
  background: $main-blue;
  color: #ffffff;
}

.contacts {
  max-height: calc(
    100vh - 100px
  ); //!96px is the combined height of the content taking up the vh aside from this component
  overflow-y: auto;
  border-right: 1px solid $gray-d;
}

.user-filters {
  display: flex;
  padding-left: 2.2rem;
  border-bottom: 1px solid $gray-d;
}

.user-filter {
  padding-bottom: 0.6rem;
  margin-right: 3rem;
}

.user-filter:hover {
  cursor: pointer;
}

.user-filter.selected {
  font-weight: 700;
  border-bottom: 2px solid $main-orange;
}

.search-bar {
  display: flex;
  justify-content: center;
  align-items: center;
  // background-color: $gray-f;
  // width: calc(100% - 440px);
  // margin-right: 3rem;
  border-radius: 0.4rem;
  padding: 2.4rem 2.2rem 1.2rem;
  // margin-bottom: 4rem;
}

.search-bar__icon {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: $gray-f;
  height: $default-input-height;
  border-top-left-radius: $input-radius;
  border-bottom-left-radius: $input-radius;
  width: 34px;
  max-width: 100%;
  padding: 0 0.8rem;

  img {
    max-width: 100%;
  }
}

input[name='searchbar'] {
  background-color: $gray-f;
  border: none;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  width: 100%;
  padding-left: 0.2rem;
}

input[name='searchbar']:focus {
  outline: none;
}

.tag-filter {
  max-width: 100%;
  padding: 0 2.2rem;
  .select-container {
    .filter-by-tag {
      max-width: 148px;
    }
    .select-icon {
      top: 25%;
    }
  }
  .gray-icon-background {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: $gray-f;
    width: 42px;
    height: 38px;
    border-radius: 5px;
  }
}

.select-and-error {
  display: flex;
  flex-direction: column;
  label {
    margin: 0;
  }
}

.message-heading {
  display: flex;
  justify-content: space-between;
  align-items: center;
  // width: 18vw;
  height: 35px;
  width: 200px;
  padding: 0.5rem;
  margin: 2rem 0 0.5rem;
  span {
    padding: 0.7rem;
    border-radius: 6px;
  }
  span:hover {
    color: #ffffff;
    background-color: $gray-d;
    cursor: pointer;
  }
}

.chatbox {
  background-color: $gray-f;
}

.header {
  padding: 2rem 2.4rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid $gray-d;
  h2 {
    font-size: 2.2rem;
    margin-bottom: 0.5rem;
  }
  span {
    color: #787d87;
  }
}

.contacts-and-chat-container {
  display: flex;
  flex-direction: row;
  max-height: calc(
    100vh - 96px
  ); //!96px is the combined height of the content taking up the vh aside from this component
  // overflow: hidden;
  overflow-y: auto;
  // padding: $form-padding 0;
  ul {
    display: flex;
    flex-direction: column;
    // align-items: center;
    background-color: #ffffff;
    li {
      display: flex;
      flex-direction: column;
      // justify-content: center;
      list-style: none;
      font-size: $body-font-l;
      text-transform: capitalize;
      // padding: $nav-link-padding;
      width: 100%;
      padding: 1.5rem;
      border-bottom: 1px solid $gray-f;
      .contact-name-date {
        display: flex;
        justify-content: space-between;
        align-items: center;
        .msg-date {
          color: $gray-b;
          font-size: 1.2rem;
        }
      }
      .contact-name {
        display: flex;
        align-items: center;
        font-size: 1.5rem;
        font-weight: 700;
      }

      .msg-preview {
        display: flex;
        justify-content: space-between;
        align-items: center;
        color: #787d87;
        text-transform: none;
        font-size: 1.3rem;
        margin: 1.3rem 0;
        .unread {
          display: flex;
          justify-content: center;
          align-items: center;
          background-color: #dc4141;
          color: #ffffff;
          font-size: 1rem;
          font-weight: 700;
          min-height: 20px;
          max-height: 20px;
          min-width: 20px;
          max-width: 20px;
          border-radius: 50%;
        }
      }
      .contact-tags {
        display: flex;
        .msg-tag {
          display: flex;
          align-items: center;
          font-size: 1rem;
          font-weight: 700;
          text-transform: uppercase;
          height: 16px;
          padding: 0.8rem;
          margin-right: 0.5rem;
          border-radius: 3px;
        }
      }
    }
    li:hover,
    li:focus {
      background-color: $gray-f;
      // border-radius: $form-radius;
      cursor: pointer;
    }
    .gray-background {
      background-color: $gray-f;
    }
  }
  .recent-contacts {
    // height: $message-container-height;
    // width: 18vw;
    width: 300px;
    // min-width: 150px;
    overflow: hidden;
    overflow-y: auto;
    margin-top: 2rem;
    // margin-right: 1.25vw;
    .new-contact {
      // width: 105%;
      input {
        width: 100%;
        // border: none;
      }
      input:hover,
      input:focus {
        outline: none;
      }
    }
    .new-contact:hover,
    .new-contact:focus {
      background-color: #ffffff;
    }

    li {
      // min-width: 110px;
      // width: 14vw;
      margin: 0.15rem 0;
      a {
        white-space: nowrap;
        // width: 13.5vw;
        // min-width: 107px;
      }
    }
  }

  .messages-chatbox-container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    background-color: $gray-f;
    width: 35vw;
    max-width: 552px;
    border-right: 1px solid $gray-d;
    // margin-top: calc(2rem + 35px + 0.5rem);
    .header {
      width: 35vw;
      .select-container {
        max-width: 148px;
        select {
          background-color: $gray-f;
          max-width: 148px;
        }
        .select-icon {
          top: 25%;
          right: 8%;
        }
      }
    }
  }

  .date-line {
    text-align: center;
    border-bottom: 1px solid $gray-e;
    line-height: 0.1em;
    margin: 2rem 0 1rem;
    span {
      background-color: $gray-f;
      color: $gray-c;
      font-size: 1.2rem;
      padding: 0 1rem;
    }
  }

  .recent-messages {
    background-color: $gray-f;
    min-height: 48vh;
    max-height: 48vh;
    overflow-x: hidden;
    overflow-y: auto;
    padding: 1rem 2.2rem;
    .message {
      width: 376px;
      max-width: 376px;
      border-radius: 10px;
      padding: 12px;
      margin: 1rem 0;
    }
  }

  .message-align {
    display: flex;
    flex-direction: column;
    strong {
      color: #787d87;
    }
    .time {
      color: $gray-c;
      font-size: 1.2rem;
    }
  }
  .message-align.twilio,
  .message-align.internal,
  .message-align.twilio span,
  .message-align.internal span {
    align-self: flex-end;
  }
  .message.twilio {
    background-color: $outbound;
  }

  .message.external {
    background-color: #ffffff;
  }

  .message.internal {
    align-self: flex-end;
    background-color: $internal;
  }

  .send-message {
    background-color: #ffffff;
    padding: 2rem 0;
    border-radius: 10px;
    margin: 2rem 2.2rem;
    .send-input {
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      padding: 0 2rem;
      #outboundSMS {
        margin: 1rem 0;
        font-size: 1.4rem;
        padding: 0;
        border: none;
      }
      #outboundSMS:focus {
        outline: none;
      }
      .send-message-btn {
        max-width: 96px;
      }
    }
  }

  .message-textarea {
    max-height: 5.4vh;
    width: 100%;
    font-size: $body-font-l;
  }
}
