// <-------------------- media queries -------------------->

@media (max-width: 768px) {
  .dashboard {
    flex-direction: column;

    .lead-data {
      width: 100%;
    }
  }

  input,
  select {
    max-width: 28vw;
  }

  .match-textarea {
    textarea {
      width: 24vw;
    }
  }

  .tutor-info-container {
    width: 52vw;
  }
}

.sms-label {
  margin-bottom: $label-margin-top;
}

@media (max-width: 1000px) {
  .main-container {
    margin-left: 4vw;
    margin-right: 4vw;
  }

  #STUDENT_DETAILS {
    .student-input {
      div {

        input,
        select,
        p {
          width: 24vw;
        }
      }
    }
  }

  .subject-inputs {
    div {

      select,
      input,
      p {
        width: 15vw;
      }
    }
  }

  .appointments {
    display: flex;
    flex-direction: column;

    div {
      textarea {
        width: 50vw;
      }
    }
  }

  .single-tutor {
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;

    .inner-tutor-container,
    .match-tutor-container {
      display: flex;
      justify-content: space-between;
      width: 100%;

      span,
      input[type='text'] {
        min-width: 7vw;
      }

      input[type='checkbox'] {
        margin-left: 2rem;
        max-width: 2vw;
      }

      input[type='radio'] {
        margin-left: 2rem;
        max-width: 2vw;
      }

      span.bg-check {
        margin-left: 0;
        width: 2vw;
      }
    }
  }

  .match-tutor-heading-container {
    white-space: nowrap;
  }

  // <-------------------- match form -------------------->
  .match-client-header-right {
    flex-direction: column;

    :first-child {
      margin-right: 0;
      margin-bottom: 0.1rem;
    }
  }

  .student-heading-container {
    .student-avatar {
      margin-right: 1.2rem;
    }
  }

  .flex.tutor-info-container.match-info-width {
    flex-direction: row;
  }
}

@media (max-width: 1200px) {
  // <-------------------- match form -------------------->

  .match-tutor-heading-container {
    max-width: 15vw;
  }

  .tutor-info-container {
    // font-size: 0.6rem;
    margin: 0;
  }
}