.main-container-margin-left {
  margin-left: 0.5 * $container-mwidth;
}

.match-container {
  position: absolute;
  width: $form-width;
  background-color: $secondary-text-color;
  z-index: 30;
  // transition: $transition;
  // margin: $default-mheight $default-mwidth;
}

.match-grid-container {
  // display: grid;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  position: relative;
  // grid-template-columns: repeat(autofit, minmax(21vw, 1fr));
  // grid-template-columns: repeat(4, minmax(20vw, 1fr));
  // gap: $default-mheight;
  // justify-items: center;
  // justify-content: center;
  // align-items: flex-start;
}

.match-card-width {
  min-width: 18vw;
  margin-right: $input-padding-left;
}

.match-container:target {
  // transition: [transition-property] [transition-duration] [transition-timing-function] [transition-delay];
  width: $form-width;
}

.match-client {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  height: 13.9vh;
}

.match-client-header-right {
  display: flex;
  flex-direction: row;
  :first-child {
    margin-right: 5rem;
  }
}

.match-info-width {
  max-width: 49%;
}

.match-client-top-padding {
  padding-top: 3.33vh;
  padding-bottom: 3.33vh;
}

.match-client-margin {
  margin-bottom: 4.54vh;
}

// .with-edit {
//   width: 27vw;
// }

.client-avatar {
  background-color: $main-yellow;
  height: 53px;
  min-height: 53px;
  width: 53px;
  min-width: 53px;
  font-size: $client-avatar-font;
  font-weight: 700;
  margin-right: 1.5rem;
  padding: 8px;
}

// <-------------------- student in match -------------------->

.match-student-height {
  height: 12vh;
}

.student-heading-container {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.student-avatar {
  background-color: $main-pink;
  height: 50px;
  min-height: 50px;
  width: 50px;
  min-width: 50px;
  font-size: $student-avatar-font;
  font-weight: 700;
  margin-right: 1.8rem;
}

input[type='radio'] {
  // -webkit-appearance: none;
  // color: $main-blue;
  height: $checkbox-width;
  width: $checkbox-width;
  display: inline-block;
  margin-right: $label-margin-top;
  border: 0.1rem solid $gray-e;
  border-radius: 0.3rem;
}
input[type='radio']:checked,
input[type='radio']:after,
input[type='radio']:hover,
input[type='radio']:focus,
input[type='radio' i],
input[type='radio' i]:hover,
input[type='radio' i]:focus {
  color: $main-blue;
  background-color: $main-blue;
}
