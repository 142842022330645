.dashboard-container {
  display: flex;
  flex-direction: column;
  padding: $form-padding;
  margin: 0 $container-mwidth;
  margin-top: 1.25 * $header-height;
}

.dashboard {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  :first-child {
    margin-right: $default-mwidth;
  }
}

.lead-data {
  display: flex;
  justify-content: center;
  align-items: center;
  background-image: linear-gradient(to right, $main-pink, $main-orange);
  color: $secondary-text-color;
  height: 25vh;
  // width: 35.5vw;
  width: 33.4vw;
  // margin-top: 7.66vh;
  margin-bottom: 2 * $default-mwidth;
  border-radius: 12px;
  .dashboard-data {
    p {
      font-size: $dashboard-font;
      font-weight: 700;
    }
    span {
      font-size: $body-font-s;
      text-transform: uppercase;
    }
  }
}

.pink-gradient {
  background-image: linear-gradient(to right, $main-pink, $main-orange);
  color: $secondary-text-color;
}

.blue-gradient {
  background-image: linear-gradient(to right, $dash-blue-b, $dash-blue-a);
  color: $secondary-text-color;
}

.password-form {
  padding: 0;

  .default-margin-top-bottom.isa_error {
    margin: 1rem 0;
  }
}

.password-reset {
  display: flex;
  flex-direction: column;
  margin: 1rem 0;
}

.password-submit {
  margin-top: 1rem;
}
