.isa_info,
.isa_success,
.isa_warning,
.isa_error {
  display: flex;
  flex-direction: row;
  align-items: center;
  height: $default-button-height;
  font-family: $font-family;
  // margin: 1rem 0;
  padding: $label-margin-top $input-padding-left;
  border-radius: $input-radius;
  cursor: pointer;
  padding-right: 3rem;
}

.isa_info {
  color: #00529b;
  background-color: #bde5f8;
  z-index: 10;
}
.isa_success {
  color: #4f8a10;
  background-color: #c9f8be;
  z-index: 10;
}
.isa_warning {
  color: #ca8516;
  background-color: #fdf7ed;
  // to cover save button on render
  z-index: 10;
}
.isa_error {
  color: $delete;
  background-color: #ffd2d2;
  z-index: 10;
}
.isa_info i,
.isa_success i,
.isa_warning i,
.isa_error i {
  margin: 1rem;
  font-size: $body-font-s;
  vertical-align: middle;
}

.warning-container {
  display: flex;
  flex-direction: column;
}

.warning-buttons {
  display: flex;
  flex-direction: row;
  align-items: center;
  button {
    margin-right: $input-padding-left;
  }
  // justify-content: center;
}
