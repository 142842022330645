.createLead {
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: $secondary-text-color;
  font-size: $body-font-m;
  background-image: $main-gradient;
  margin-top: $default-mheight;
  padding: $create-lead-padding;
  border-radius: $label-radius;
  cursor: pointer;
  width: 21.89vw;
}

.flex-heading {
  display: flex;
  justify-content: space-between;
  width: 100%;

  h3 {
    font-size: $body-font-m;
    font-weight: 500;
  }

  h3,
  i {
    color: $secondary-text-color;
  }
}

.main-container {
  margin: 0 5.99vw;
  margin-top: 1.25 * $header-height;
  padding-top: $default-mheight;

  h1 {
    margin-bottom: $form-padding;
  }

  .filter-input {
    // display: none;
    position: fixed;
    top: 15px;
    z-index: 20;
    height: 3rem;
  }
}

.drop-container {
  min-height: 70vh;
}

.grid-container {
  position: relative;
  // display: grid;
  display: flex;
  // grid-template-columns: repeat(auto-fit, minmax(21.89vw, 1fr));
  grid-template-columns: repeat(3, $card-width);
  gap: $default-mheight;
  // gap: $default-grid-gap;
  margin: 0;
  padding: 0;
  // justify-items: center;
  justify-content: space-between;
  align-items: flex-start;

  .grid-child {
    min-width: $card-width;
    max-width: $card-width;
  }
}

.grid-child {
  // width: 100%;
  // width: 21.89vw;
  margin: $default-mheight 0;
}

.card {
  // position: relative;
  background-color: $secondary-text-color;
  padding: 0 $default-mwidth $default-mwidth $default-mwidth;
}

.card-title {
  width: 90%;
  border: none;
  padding: 0;
  // keep title size the same as card heading to prevent shifting page down on click
  height: 16px;
}

.card-title:focus {
  outline: none;
}

.title {
  font-size: $header-font-m;
  // keep title size the same as h2 to prevent form shifting down on click
  height: 20px;
}

p.card.shadow.default-margin-top {
  min-width: $card-width;
}

.header-and-total {
  display: flex;
  align-items: center;

  span {
    color: $gray-c;
    font-size: $header-font-m;
    margin-left: $count-margin;
  }
}

.icon-margin {
  margin-right: 1.25 * $input-padding-left;
}

.notes {
  color: $gray-b;
  margin: 2 * $label-margin-top 0;
  max-height: $notes-max-height;
  text-overflow: ellipsis;
  overflow: hidden;
  // white-space: nowrap;
}

.tag-and-icon-container {
  // will need to revisit this when styling urgent tags
  display: flex;
  flex-direction: row-reverse;
}

.default-padding-top {
  padding-top: $default-mheight;
}

.expand {
  // display: inline-block;
  color: $gray-c;
  height: $default-mwidth;
  cursor: pointer;
  z-index: 10;
  // margin-left: auto;
  // margin-right: 0;
  // margin: 0 1rem;
}

.collapse {
  display: inline-block;
  position: absolute;
  top: 0;
  right: 0;
  color: $gray-a;
  cursor: pointer;
  z-index: 40;
  margin: $form-padding;
  // transition: $transition;
}

.default-margin-top {
  margin-top: $default-mheight;
}

.modal-background {
  display: flex;
  justify-content: center;
  background-color: $gray-f;
  padding-top: $header-height;
}

.modal-child {
  width: 85%;
}

.card-phone-link {
  margin: $label-margin-top 0;
}

.phone-link {
  white-space: nowrap;
}

.phone-link:hover,
.phone-link:focus {
  color: $main-orange;
}

.none {
  text-decoration: none;
  color: $primary-text-color;

  h4,
  p {
    margin-bottom: 0.25 * $label-margin-top;
  }
}
