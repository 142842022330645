// Login form
.login-form {
   margin-top: $header-height;
}

.login-input {
   display: flex;
   flex-direction: column;
}

.login-button {
   margin-top: $default-mheight;
}

// Leads
.form-container {
   position: absolute;
   // top: 12%;
   // left: 24%;
   top: 0%;
   left: 0%;
   width: $form-width;
   background-color: $secondary-text-color;
   z-index: 30;
   // transition: $transition;
   // margin: $default-mheight $default-mwidth;
}

.form-container:target {
   // transition: [transition-property] [transition-duration] [transition-timing-function] [transition-delay];
   width: $form-width;
}

.form-grid {
   display: grid;
   grid-template-columns: repeat(2, minmax(435px, 1fr));
}

form {
   font-size: $body-font-m;
   color: $gray-a;
   padding: $form-padding;
   border-radius: $form-radius;

   section {
      div {
         // height = 1.8rem
         display: flex;
         flex-direction: column;
      }
   }
}

.row {
   display: flex;
   justify-content: space-between;
   align-items: center;

   p {
      font-family: sans-serif;
      height: $default-input-height;
      color: $gray-a;
      // font-size: $input-font-size;
      // padding: $label-margin-top $input-padding-left;
      padding: 1.8rem 1.3rem;
      border-radius: $input-radius;
      border: 1px solid $gray-d;
   }

   textarea:first-of-type {
      margin-right: 2 * $label-margin;
   }
}

input,
select,
.autofill {
   width: $default-input-width;
   height: $default-input-height;
   color: $gray-a;
   font-size: $input-font-size;
   padding: $label-margin-top $input-padding-left;
   border-radius: $input-radius;
}

input,
select {
   border: 1px solid $gray-d;
}

.select-container {
   // max-width: 100%;
   position: relative;
}

.select-icon {
   position: absolute;
   top: 30%;
   right: 5%;
   width: 10px;
   height: 6px;
}

select {
   -webkit-appearance: none;
   -moz-appearance: none;
   -o-appearance: none;
}

label {
   color: $gray-b;
   font-size: $label-font-size;
   font-weight: 700;
   margin: $label-margin 0;
}

.heading {
   display: flex;
   flex-direction: row;
   justify-content: space-between;
   align-items: center;
   margin-bottom: $form-heading-m;
}

.left-heading {
   display: flex;
   flex-direction: row;
   // justify-content: center;
   align-items: center;

   h3,
   h4 {
      margin-right: $input-padding-left;
   }
}

.buttons-container {
   display: flex;
   flex-direction: row;
   justify-content: space-between;
}

#LEAD_DATA {
   div:first-child {
      margin-right: 1rem;
   }
}

#CLIENT_DETAILS {
   margin-top: $form-padding;

   p {
      width: $default-input-width;
   }

   .row {

      div:first-child,
      input[type='email'] {
         margin-right: 1rem;
      }
   }
}

.checkbox {
   display: flex;
   flex-direction: row;
   justify-content: left;
   align-items: center;
   height: $default-input-height;

   input[type='checkbox'] {
      height: $checkbox-width;
      width: $checkbox-width;
      display: inline-block;
      margin-right: $label-margin-top;
      border: 0.1rem solid $gray-e;
      border-radius: 0.3rem;
   }
}

.default-input-width {
   width: $default-input-width;
}

.checkbox-row {
   display: flex;
   align-items: center;
   margin: $label-margin-top 0;
}

.checkbox-container {
   display: flex;
   flex-direction: row;
   justify-content: space-between;
   align-items: center;
}

.checkbox-margin {
   margin-right: 4rem;
}

textarea {
   resize: none;
   font-size: $body-font-l;
   height: $textarea-height;
   padding: $label-margin-top $input-padding-left;
   border: 1px solid $gray-d;
   border-radius: $input-radius;
}

#EXTRA_FIELDS {
   margin-top: $label-margin-top;
}

// <-------------------- student section -------------------->
#STUDENT_DETAILS {
   .student-container {
      background-color: $gray-f;
      margin-top: $default-mheight;
      padding: 0 $form-padding $form-padding $form-padding;
      border-radius: $form-radius-b;
   }

   .heading-margin {
      margin-top: $form-padding;
   }

   .student-input {
      div {

         input,
         select,
         p {
            width: $student-input-width;
         }
      }

      div:first-child {
         margin-right: 1rem;
      }
   }
}

.add {
   background-color: $gray-f;
   font-size: $body-font-l;
   cursor: pointer;
   padding: 1rem $form-padding;
   margin: $default-mheight 0;
   border-radius: $form-radius;
}

.add-button {
   background-color: $main-orange;
}

.add-button:hover,
.add-button:focus {
   background-color: $secondary-text-color;
   color: $main-orange;
   border: 0.15rem solid $main-orange;
}

.edit {
   color: $main-blue;
   font-size: $delete-font-size;
   text-decoration: underline;
   text-transform: uppercase;
   cursor: pointer;
}

.delete {
   color: $delete;
   font-size: $delete-font-size;
   text-decoration: underline;
   text-transform: uppercase;
   cursor: pointer;
}

.save {
   background-color: $secondary-text-color;
   color: $main-orange;
   border: 0.15rem solid $main-orange;
}

.save:hover,
.save:focus {
   background-color: $main-orange;
   color: $secondary-text-color;
   border: 0.15rem solid $main-orange;
}

.reverse-save {
   background-color: $main-orange;
   color: $secondary-text-color;
   border: 0.15rem solid $main-orange;
}

.reverse-save:hover,
.reverse-save:focus {
   background-color: $secondary-text-color;
   color: $main-orange;
   border: 0.15rem solid $main-orange;
}

.subjects-container {
   position: relative;
   margin-top: $form-padding;
}

.subject-inputs {
   div {

      select,
      input,
      p {
         width: $subject-input-width;
      }
   }
}

.subject-text {
   height: $textarea-height;
   width: $student-input-width;
   padding: $label-margin-top $input-padding-left;
   border: 1px solid $gray-d;
   border-radius: $input-radius;
}

.student-input-width {
   width: $student-input-width;
}

.add-button-container {
   display: flex;

   button {
      margin-left: auto;
      margin-right: 0;
   }
}

// <-------------------- contract section -------------------->

.contract-subjects-container {
   display: flex;
   flex-direction: row;
   margin-bottom: $default-mheight;
}

.right-heading {
   display: flex;
   flex-direction: row;
}

.x {
   margin-left: $input-padding-left;
   cursor: pointer;
}

.contract-label-container {
   display: inline-block;

   span {
      font-size: $label-font-s;
      font-weight: 700;
      padding: 0.5rem;
   }
}

.contract-tutors-container {
   margin-top: $default-mheight;
}

.filters-container {
   display: flex;
   flex-direction: row;
   align-items: center;
   font-size: $body-font-m;
   border: 1px solid $main-orange;
   border-radius: $nav-link-radius;
   z-index: 10;
}

.filter {
   display: flex;
   justify-content: center;
   align-items: center;
   height: 4.9vh;
   width: 10vw;
   cursor: pointer;
}

.location-filter {
   color: $main-orange;
   border-top-left-radius: 6px;
   border-bottom-left-radius: 6px;
   border-right: 1px solid $main-orange;
}

.subject-filter {
   color: $main-orange;
   border-top-right-radius: 6px;
   border-bottom-right-radius: 6px;
}

.highlighted {
   background-color: $main-orange;
   color: $secondary-text-color;
   z-index: -10;
}

.zoom {
   border: 1px solid $gray-c;
   border-radius: $nav-link-radius;
   margin-left: $input-padding-left;
   font-size: $header-font-l;

   div {
      width: 4.1vw;
   }

   div:hover {
      background-color: $gray-c;
      color: $secondary-text-color;
      font-weight: 700;
   }

   .plus {
      border-right: 1px solid $gray-c;
      border-top-left-radius: 6px;
      border-bottom-left-radius: 6px;
   }

   .minus {
      border-top-right-radius: 6px;
      border-bottom-right-radius: 6px;
   }
}

// <-------------------- find tutors -------------------->

.tutor-results {
   max-height: $tutor-container-height;
   margin-bottom: $label-margin-top;
}

.form-container-border {
   padding: $input-padding-left $form-padding;
   border: 1px solid $gray-e;
   border-top-left-radius: 0.6rem;
   border-top-right-radius: 0.6rem;
}

.single-tutor-bottom-padding {
   margin-bottom: $input-padding-left;
}

.tutor-in-lead-border {
   padding: $input-padding-left $form-padding 0;
   border: 1px solid $gray-e;
   border-top-left-radius: 0.6rem;
   border-top-right-radius: 0.6rem;
   position: relative;
}

.urgent-margin-bottom {
   margin-bottom: 1rem;
}

.tutor-container-border {
   padding: $input-padding-left $form-padding 0;

   border: 1px solid $gray-e;
   border-top-left-radius: 0.6rem;
   border-top-right-radius: 0.6rem;
}

.subject-alert-container {
   display: flex;
   flex-direction: row;
   align-items: center;
   margin-bottom: $default-mheight;

   button {
      margin-left: $input-padding-left;
   }
}

.tutor-search-container {
   display: flex;
   flex-direction: row;
   align-items: center;
   position: relative;
   margin-bottom: $default-mheight;
}

.autofill-container {
   position: absolute;
   top: 100%;
   left: 0;
   background-color: $secondary-text-color;
   border: 1px solid $gray-e;
   border-radius: 0.6rem;
   cursor: pointer;
}

.autofill:hover,
.autofill:focus {
   background-color: $gray-e;
}

.single-tutor-add-container {
   display: flex;
   flex-direction: row;
}

.add-tutors-button {
   max-height: 5vh;
   max-width: 4vw;
}

.search-icon {
   color: $gray-a;
   background-color: $gray-f;
   height: $default-input-height;
   border-top-left-radius: $input-radius;
   border-bottom-left-radius: $input-radius;
   border-top: solid 1px $gray-d;
   border-bottom: solid 1px $gray-d;
   border-left: solid 1px $gray-d;
   padding: 0.8rem;
}

.single-tutor-search {
   background-color: $gray-f;
   width: 38vw;
   margin-right: $input-padding-left;
   padding-left: 0;
   border-top-left-radius: 0;
   border-bottom-left-radius: 0;
   border-left: none;
}

.single-tutor-search:focus {
   outline: none;
}

// <-------------------- single tutor -------------------->
.single-tutor {
   display: flex;
   flex-direction: row;
   justify-content: space-between;
   align-items: flex-start;
}

.inner-tutor-container {
   display: flex;
   align-items: flex-start;
   flex-direction: row;
   margin-top: 10px;
   position: relative;
}

.tutor-heading-container {
   display: flex;
   flex-direction: row;
   align-items: center;
   min-width: 14vw;
}

.match-tutor-heading-container {
   display: flex;
   flex-direction: row;
   align-items: center;
}

.avatar {
   display: flex;
   justify-content: center;
   align-items: center;
   color: $secondary-text-color;
   height: 3.4rem;
   width: 3.4rem;
   font-size: $avatar-font;
   margin-right: 0.5 * $input-padding-left;
   padding: 1rem;
   border-radius: 50%;
}

.find-tutor-avatar {
   background-color: $dash-blue-b;
}

.avatar-header {
   height: 4vh;
}

.section-padding {
   padding: $form-padding 0;
}

.sms {
   font-size: $body-font-m;
}

.sms-input-container {
   button {
      width: $default-button-width;
      min-width: 180px;
   }
}

.sms-input {
   font-size: $body-font-m;
}

.sms-label {
   display: flex;
   align-items: center;
   margin-top: 0.4rem;
   z-index: 10;

   img {
      margin-left: 0.4rem;
   }
}

.label-margin {
   margin: $label-margin-top 0;
}

.tutor-link {
   width: 7vw;
   color: $main-blue;
   text-decoration: underline;
   white-space: nowrap;
   margin-left: 1rem;
}

.reverse-blue {
   background-color: $secondary-text-color;
   color: $main-blue;
   border: 1px solid $main-blue;
}

.reverse-blue:hover,
.reverse-blue:focus {
   background-color: $main-blue;
   color: $secondary-text-color;
   border: 1px solid $main-blue;
}

.tutor-info {
   color: $gray-b;
   margin-left: 1rem;
   height: 16px;
}

.span-clone {
   background-color: $secondary-text-color;
   font-size: $body-font-m;
   width: 6vw;
   padding: 0;
   border: none;
}

.subject-hover {
   cursor: pointer;
}

.subjects-offered {
   width: 100%;
   border: 1px solid $gray-e;
   padding: 2rem;
   margin-bottom: 2rem;
   border-radius: 6px;

   .subject-hover {
      margin-bottom: 0.75rem;
   }
}

.bg-check,
.transit-mode,
.transit-time,
.distance-to-client {
   display: flex;
   justify-content: center;
   width: 5vw;
}

.wage-input {
   height: 5vh;
   width: 6vw;
   padding: 0;
   padding-left: 0.4rem;
   margin-right: 0.485rem;
}

.subject-button {
   cursor: pointer;
   margin-right: 2rem;
}

.select-tutor-checkbox {
   width: 0.85 * $checkbox-width;
   height: 16px;
   border-radius: 3px;
   margin-right: 2rem;
}

.clientSMS {
   padding: $default-mheight $form-padding;
   border-right: 1px solid $gray-e;
   border-bottom: 1px solid $gray-e;
   border-left: 1px solid $gray-e;
   border-bottom-left-radius: 0.6rem;
   border-bottom-right-radius: 0.6rem;
}

textarea.sms {
   height: $textarea-smaller;
}

.timestamp {
   margin: $label-margin-top 0;
}

.save-buttons {
   display: flex;
   justify-content: flex-start;
   position: relative;
   width: 100%;
}

.absolute-btn {
   position: absolute;
   top: 0;
   right: 0;
}

.delete-btn {
   background-color: $delete;
}

.delete-btn:hover,
.delete-btn:focus {
   background-color: $secondary-text-color;
   color: $delete;
   border: 1px solid $delete;
}

.search-tutor-button {
   button {
      width: $default-button-width;
      min-width: 180px;
   }
}