@import './partials/variables';
@import './partials/nav';
@import './partials/dashboard';
@import './partials/form';
@import './partials/leads';
@import './partials/match';
@import './partials/errors';
@import './partials/labels';
@import './partials/messages';
@import './partials/tags';
@import './partials/media';
@import './partials/resourceHubUpload';
@import './partials/readingPortalUsers';
@import './partials//storiesUpload';

html {
  line-height: 1.15;
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
  font-size: 62.5%;
}

article,
aside,
footer,
// header,
// nav,
section {
  display: block;
}

h1 {
  font-size: 2em;
  margin: 0.67em 0;
}

figcaption,
figure,
main {
  display: block;
}

figure {
  margin: 1em 40px;
}

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible;
}

pre {
  font-family: monospace, monospace;
  font-size: 1em;
}

a {
  background-color: transparent;
  -webkit-text-decoration-skip: objects;
}

abbr[title] {
  border-bottom: none;
  text-decoration: underline;
  text-decoration: underline dotted;
}

b,
strong {
  font-weight: inherit;
}

b,
strong {
  font-weight: bolder;
}

code,
kbd,
samp {
  font-family: monospace, monospace;
  font-size: 1em;
}

dfn {
  font-style: italic;
}

mark {
  background-color: #ff0;
  color: #000;
}

small {
  font-size: 80%;
}

sub,
sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline;
}

sub {
  bottom: -0.25em;
}

sup {
  top: -0.5em;
}

audio,
video {
  display: inline-block;
}

audio:not([controls]) {
  display: none;
  height: 0;
}

img {
  border-style: none;
  max-width: 100%;
}

svg:not(:root) {
  overflow: hidden;
}

button,
input,
optgroup,
select,
textarea {
  font-family: $font-family;
  // font-family: sans-serif;
  font-size: 100%;
  line-height: 1.15;
  margin: 0;
}

button,
input {
  overflow: visible;
}

button,
select {
  text-transform: none;
}

button,
html [type='button'],
[type='reset'],
[type='submit'] {
  -webkit-appearance: button;
}

button::-moz-focus-inner,
[type='button']::-moz-focus-inner,
[type='reset']::-moz-focus-inner,
[type='submit']::-moz-focus-inner {
  border-style: none;
  padding: 0;
}

button:-moz-focusring,
[type='button']:-moz-focusring,
[type='reset']:-moz-focusring,
[type='submit']:-moz-focusring {
  outline: 1px dotted ButtonText;
}

fieldset {
  padding: 0.35em 0.75em 0.625em;
}

legend {
  box-sizing: border-box;
  color: inherit;
  display: table;
  max-width: 100%;
  padding: 0;
  white-space: normal;
}

progress {
  display: inline-block;
  vertical-align: baseline;
}

textarea {
  overflow: auto;
}

[type='checkbox'],
[type='radio'] {
  box-sizing: border-box;
  padding: 0;
}

[type='number']::-webkit-inner-spin-button,
[type='number']::-webkit-outer-spin-button {
  height: auto;
}

[type='search'] {
  -webkit-appearance: textfield;
  outline-offset: -2px;
}

[type='search']::-webkit-search-cancel-button,
[type='search']::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  -webkit-appearance: button;
  font: inherit;
}

details,
menu {
  display: block;
}

summary {
  display: list-item;
}

canvas {
  display: inline-block;
}

template {
  display: none;
}

[hidden] {
  display: none;
}

* {
  // padding: 4px;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: $font-family;

  a {
    text-decoration: none;
  }
}

body {
  margin: 0;
  // reset font size to 10px
  font-size: 1rem;
  // push all content left of nav bar
  padding-left: $nav-width;
  background-color: $gray-f;
  color: $gray-a;
  overflow-x: hidden;
}

nav,
p,
div {
  font-size: $body-font-m;
}

h1 {
  font-size: $header-font-xl;
  margin: 0;
  margin-bottom: 3vh;
  // margin-top: $form-padding;
  // margin: 0.5 * $form-padding 0;
}

h2 {
  font-size: $header-font-l;
}

h3 {
  font-size: $header-font-m;
}

h4 {
  font-size: $body-font-m;
}

.orange-text {
  color: $main-orange;
}

.normal-weight {
  font-weight: 400;
}

input[type='checkbox']:checked {
  background-color: $main-blue;
}

button {
  width: $default-button-width;
  min-height: $default-button-height;
  font-size: $button-font-size;
  font-weight: $button-font-weight;
  background-color: $main-blue;
  color: $secondary-text-color;
  // text-transform: uppercase;
  // padding: 6px;
  padding: 0 $button-side-padding;
  border-radius: $button-radius;
  white-space: nowrap;
  border: none;
}

button:hover,
button:focus {
  background-color: $secondary-text-color;
  color: $main-blue;
  border: 0.15rem solid $main-blue;
  cursor: pointer;
  outline: none;
}

button:disabled,
button[disabled] {
  background-color: $gray-f;
  color: $gray-a;
  border: none;
  cursor: not-allowed;
}

.shadow {
  box-shadow: 0.2rem 0.2rem 0.3rem $main-shadow;
}

.white-background {
  background-color: #ffffff;
  border-radius: $input-radius;
  padding: 1.2rem;
}

.flex {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.flex-column {
  display: flex;
  flex-direction: column;
}

.center {
  justify-content: center;
  align-items: center;
}

.flex-wrap {
  flex-wrap: wrap;
}

.nowrap {
  white-space: nowrap;
}

.inline-block {
  display: inline-block;
}

.align-right {
  margin-left: auto;
  margin-right: 0;
}

.gray-a-font {
  color: $gray-a;
}

.gray-c-font {
  color: $gray-c;
}

.red-font {
  color: $delete;
}

.green-font {
  color: $success;
}

.l-header-font {
  font-size: $header-font-l;
}

.m-header-font {
  font-size: $header-font-m;
}

.form-radius {
  border-radius: $form-radius-b;
}

.form-padding {
  padding: $form-padding;
}

.form-padding-sides {
  padding-left: $form-padding;
  padding-right: $form-padding;
}

.top-bottom-padding {
  padding-top: $default-mheight;
  padding-bottom: $default-mheight;
}

.rounded-gray {
  background-color: $gray-f;
  border-radius: 0.4rem;
}

.default-margin-top {
  margin-top: $default-mheight;
}

.default-margin-bottom {
  margin-bottom: $default-mheight;
}

.default-margin-top-bottom {
  margin: $default-mheight 0;
}

.input-margin-left {
  margin-left: $input-padding-left;
}

.input-margin-right {
  margin-right: $input-padding-left;
}

.half-label-margin-top {
  margin-top: 0.5 * $label-margin-top;
}

.label-margin-top {
  margin-top: $label-margin-top;
}

.label-margin-bottom {
  margin-bottom: $label-margin-top;
}

.double-label-margin-bottom {
  margin-bottom: 2 * $label-margin-top;
}

.raised {
  z-index: 10;
}

.pointer {
  cursor: pointer;
}

.nowrap {
  white-space: nowrap;
}

.scroll {
  overflow-y: auto;
}

// @media (max-width:xxx) {

// }
