.reading-portal-users {
  display: grid;
  grid-template-columns: auto auto auto;
  &__title {
    font-size: 2rem;
    font-weight: bold;

    padding: 1.5rem;
  }
  &__item {
    margin: 1.5rem 0;
    padding: 1.5rem;
    font-weight: bold;
    cursor: pointer;
  }
  &__first-item {
    text-align: start;
  }
  &__second-item {
    text-align: center;
  }
  &__last-item {
    text-align: end;
  }
}
