.tags {
  display: flex;
  flex-direction: column;
}

.label {
  display: inline-block;
  font-size: $label-font-s;
  background-color: $tag-teal-c;
  color: $secondary-text-color;
  text-transform: uppercase;
  border-radius: $label-radius;
  margin: 0.25rem;
  padding: 0.5vh 0.7vw;

  /* for word-wrapping inside label */
  -ms-word-break: break-all;
  word-break: break-all;

  /* Non standard for webkit */
  word-break: break-word;

  -webkit-hyphens: auto;
  -moz-hyphens: auto;
  -ms-hyphens: auto;
  hyphens: auto;
}

.like-label {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: $label-font-s;
  background-color: $gray-d;
  color: $secondary-text-color;
  text-transform: uppercase;
  border-radius: $label-radius;
  margin: 0.25rem;
  padding: 0.5vh 0.7vw;
  cursor: pointer;
}

.like-label:hover,
.like-label:focus {
  background-color: $gray-b;
}

.labels-container {
  display: flex;
  flex-wrap: wrap;
  margin-top: 2 * $label-margin-top;
}

.label-container {
  display: flex;
  flex-wrap: wrap;
  :first-child {
    margin-left: 0;
  }
  span {
    display: flex;
    align-items: center;
    font-size: $label-font-s;
    font-weight: 700;
    padding: 0.75rem;
  }
}

.none {
  .labels-container {
    .label {
      // font-size: 0.7rem;
      margin: 0.15rem 0;
      margin-right: 0.15rem;
    }
    :last-child {
      margin-bottom: 0;
    }
  }
}

.label-padding-top {
  padding: 0.5rem;
}

// <-------------------- subject grade labels -------------------->

// format: subject-gradeNumber ex. calculus-12

.english-12,
.english-11,
.english-10,
.english-9 {
  background-color: $tag-purple-a;
}

.english-8,
.english-7,
.english-6,
.english-5 {
  background-color: $tag-purple-b;
}

.english-4,
.english-3,
.english-2,
.english-1,
.english-kindergarten {
  background-color: $tag-purple-c;
}

.french-12,
.french-11,
.french-10,
.french-9 {
  background-color: $tag-teal-a;
}

.french-8,
.french-7,
.french-6,
.french-5 {
  background-color: $tag-teal-b;
}

.french-4,
.french-3,
.french-2,
.french-1,
.french-kindergarten {
  background-color: $tag-teal-c;
}

.math-12,
.math-11,
.math-10,
.math-9 {
  background-color: $tag-green-a;
}

.math-8,
.math-7,
.math-6,
.math-5 {
  background-color: $tag-green-b;
}

.math-4,
.math-3,
.math-2,
.math-1,
.math-kindergarten {
  background-color: $tag-green-c;
}

.science-12,
.science-11,
.science-10,
.science-9 {
  background-color: $tag-blue-a;
}

.science-8,
.science-7,
.science-6,
.science-5 {
  background-color: $tag-blue-b;
}

.science-4,
.science-3,
.science-2,
.science-1,
.science-kindergarten {
  background-color: $tag-blue-c;
}
