nav {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: $nav-width;
  min-width: 200px;
  max-width: 254px;
  background-color: $nav-color;
  padding: $nav-padding;
  z-index: 50;

  .link {
    display: flex;
    justify-content: left;
    align-items: center;
    height: $nav-link-height;
    width: $nav-link-width;
    max-width: 85%;
    color: $nav-font-color;
    font-size: $nav-font-size;
    font-weight: 500;
    text-align: left;
    padding: $nav-link-padding;

    i {
      color: $gray-f;
      font-size: 1.4rem;
      margin-right: $label-font-size;
    }
  }

  .link:hover,
  .link:focus {
    background-color: $nav-link-hover;
    color: $main-orange;
  }
}

.logo {
  h3 {
    color: $nav-font-color;
    font-size: $logo-font;
    font-weight: 400;
    text-transform: uppercase;
    // letter-spacing: 0.214rem;
  }

  max-width: $nav-link-width;
  margin-top: $default-mheight;
  margin-bottom: 1.2 * $default-mheight;

  img {
    height: auto;
    max-width: 100%;
  }
}

header {
  background-color: #ffffff;
  height: $header-height;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 0 $form-padding;
  box-shadow: $top-nav-shadow;
  // z-index: 1;
}

.login-header {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 20;
  // width: 105%;
  justify-content: flex-start;
  // padding-left: $form-padding;
  padding: 0 $container-mwidth;
  margin-bottom: $form-padding;

  .flex.align-right {
    .header-avatar {
      background-color: $main-yellow;
      height: 1.84vw;
      width: 1.84vw;
      font-size: $label-font-s;
      padding: 1.4rem;
    }
  }
}

.search-bar-container {
  margin-left: 20rem;
}

.logout:hover {
  color: $delete;
}